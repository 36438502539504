<template>
  <div class="b-container">
    <div class="container pt-4 px-0">
      <div class="contact row mx-0">
        <div class="title col-lg col-sm-12 mx-sm-auto">Follow Us On</div>
        <div
          class="sm-container col-lg-2 col-xm-4 mx-lg-0 mx-auto mt-lg-0 mt-3 row"
        >
          <i class="fab fa-facebook"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fab fa-instagram"></i>
          <i class="fab fa-twitter"></i>
        </div>
      </div>
      <div class="row main px-lg-0 mx-0 justify-content-center">
        <div class="col-lg-4 col-12 px-4 pe-lg-3 left">
          <img src="../assets/logo.png" alt="" />
          <div class="px-lg-0 px-5">
            <p>
              <span>AL RADWAN </span>
              Company is one of the most important leading healthcare prouducts
              companies in Syria.
            </p>
          </div>
        </div>
        <div class="col-lg-1 px-0"></div>
        <div class="col-lg-2 col-12 px-0 row hf">
          <div class="title-footer col-12">Helpful Links</div>
          <ul class="col">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="products-main">Categories</router-link></li>
            <li><router-link to="about">About</router-link></li>
          </ul>
          <ul class="col">
            <li><router-link to="allevents">News</router-link></li>
            <li><router-link to="gallery">Gallery</router-link></li>
            <li><router-link to="contactus">Contact</router-link></li>
          </ul>
        </div>
        <div class="col-lg-2 col-12 pe-lg-0 cata">
          <div class="title-footer">Category</div>
          <ul class="cata-m">
            <li>Orthopodics</li>
            <li>Neurosurgery</li>
            <li>Dentel</li>
          </ul>
        </div>
        <div class="col-lg-3 col-12 ps-lg-5 pe-0 com-add">
          <div class="title-footer">Company Address</div>
          <ul>
            <li><i class="fas fa-phone-alt"></i> +963-21-2646467</li>
            <li>
              <i class="fas fa-map-marker-alt"></i> syria - Aleppo -
              Al-Mouhafaza
            </li>
            <li><i class="fas fa-envelope"></i> info@alradowan-medical.com</li>
          </ul>
        </div>
      </div>
      <div class="companys col row py-3 mx-0">
        <div class="comp col-lg col-12">
          <img src="../assets/com-1.png" alt="" />
          <img src="../assets/com-2.png" alt="" />
          <img src="../assets/com-3.png" alt="" />
          <img src="../assets/com-4.png" alt="" />
          <img
            src="../assets/com-6.png"
            class="d-lg-inline-block d-none"
            alt=""
          />
          <img
            src="../assets/com-5.png"
            class="d-lg-inline-block d-none"
            alt=""
          />
          <img
            src="../assets/com-7.png"
            class="d-lg-inline-block d-none"
            alt=""
          />
        </div>
        <div class="copy-right col-lg-3 col-12 mt-lg-0 mt-4">
          <p class="c">CopyRight&#169;2021 All Rights Reversed</p>
          <p class="b">BEETRONIX</p>
        </div>
      </div>
      <!-- <v-treeview /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-size: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #e3e3e3;
  text-decoration: none;
}
.b-container {
  margin-top: 40px;
  position: relative;
  background-color: #1b1b1b;
  background: url("../assets/footer-background.png");
  background-size: cover;
}
.sm-container {
  width: 200px !important;
}

.sm-container svg {
  width: 25%;
  padding: 0;
  filter: invert(100%);
  height: 25px;
}
.contact {
  padding: 18px 0;
}

.contact .title {
  font-size: 16px;
  font-family: MontserratBold;
}
.main {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 30px 0;
  height: 200px;
}

.left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.left img {
  width: 120px;
  padding-top: 15px;
  padding-right: 10px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;

  filter: brightness(100);
}

.left div {
  padding: 13px 0;
  max-width: 200px;
  overflow-y: hidden;
}

.left div p {
  line-height: 1.4rem;
  font-size: 13px;
  font-family: MontserratMedium;
}

.left div p span {
  font-family: MontserratMedium;
  color: var(--orange-color);
  font-size: 13px;
}

ul {
  list-style: none;
}
.title-footer {
  padding: 10px 0;
  font-size: 17px;
  font-family: MontserratBold;
}
.main > div > ul > li {
  padding: 4px 0;
  margin: 0;
}
ul {
  padding: 0;
}
.cata {
  padding-left: 65px !important;
}

.cata-m {
  padding: 0 !important;
}
.com-add li {
  min-width: 230px;
}
.com-add li svg {
  width: 1.1rem;
  padding-right: 5px;
  filter: invert(100%);
}

.comp img {
  width: 60px;
  margin-right: 25px;
  filter: grayscale(100%);
  transition: 200ms;
}
.comp img:hover {
  filter: grayscale(0%);
  transform: scale(1.2);
}
.copy-right {
  text-align: right;
}

.copy-right p {
  margin: 0 !important;
}

.copy-right .c {
  color: #9d9d9d;
  font-size: 11px;
}

.copy-right .b {
  font-family: MontserratBold;
  font-weight: bold;
  font-size: 12px;
}
a:hover {
  color: var(--orange-color) !important;
}
@media only screen and (max-width: 992px) {
  * {
    text-align: center;
    font-size: 18px;
  }
  .sm-container {
    width: 250px !important;
  }
  .sm-container svg {
    height: 40px;
  }
  .main {
    height: auto;
  }
  .left {
    display: block;
  }
  .left img {
    width: 160px;
    margin-bottom: 10px;
  }
  .left p,
  .left span {
    font-size: 18px !important;
  }
  .left div {
    max-width: none;
  }
  .cata {
    padding: 0 !important;
  }
  .title-footer {
    font-size: 22px;
  }
}
</style>
