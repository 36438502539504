<template>
  <div class="cont">
    <div class="img"><img src="../../assets/5.jpg" alt="" /></div>
    <div class="left">
      <div class="date">
        <img src="../../assets/calendar.png" alt="" /> 25 june 2021
      </div>
      <div class="title">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newscard",
};
</script>
<style scoped>
.cont {
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  height: 60px;
}
.img {
  width: 20%;
  height: 100%;
  display: inline-block;
  padding-right: 10px;
}
.img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.left {
  width: 80%;
  display: inline-block;
}
.date {
  color: gray;
  font-size: 10px;
}
.date img {
  width: 10px;
  padding-bottom: 4px;
}
.title {
  line-height: 1.3rem;
}
@media only screen and (max-width: 992px) {
  .cont {
    display: block;
    height: auto;
  }
  .img {
    width: 100%;
    height: 350px;
  }
  .left {
    width: 100%;
    padding: 15px 0;
  }
  .date {
    font-size: 15px;
  }
  .date img {
    width: 14px;
  }
  .title {
    font-size: 20px;
    line-height: 1.5rem;
    line-break: anywhere;
  }
}
</style>
