<template>
  <div class="container">
    <div class="title">
      <div class="title-name">Contact Us</div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'Contact Us', path: '/contactus', active: 'true' },
        ]"
      ></breadcrumbs>
    </div>
    <div class="main row">
      <div class="left col-lg-6 col-12">
        <form action="">
          <div class="f-title">Get In Touch</div>
          <input type="email" placeholder="Email" class="email" />
          <select class="type" placeholder="Email">
            <option value="Green">Green</option>
            <option value="Red">Red</option>
            <option value="Blue">Blue</option>
            <option value="Black">Black</option>
            <option value="Red">Yellow</option>
          </select>
          <label for="massage">Massage</label>
          <input type="text" id="massage" class="massage" />
          <button class="btn-send">Send</button>
        </form>
      </div>
      <div class="right col-lg-5 col-12">
        <div class="contact">
          <div class="phone">
            <img src="../../assets/phone.png" alt="" />+963-21-2646467
          </div>
          <div class="addres">
            <img src="../../assets/addres.png" alt="" />Syria - Allepo -
            Al-Mohafaza
          </div>
          <div class="mail">
            <img src="../../assets/email.png" alt="" />info@alradwan-media.com
          </div>
        </div>
        <div class="social">
          <i class="fab fa-facebook-f"></i>
          <i class="fab fa-linkedin-in"></i>
          <i class="fab fa-instagram"></i>
          <i class="fab fa-twitter"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
export default {
  components: {
    Breadcrumbs,
  },
};
</script>

<style scoped>
* {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #1b1b1b;
}

.title {
  padding: 90px 0 50px;
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  margin-bottom: 10px;
}
.main {
  border-top: 2px solid var(--borders-color);
  padding-top: 30px;
}
.left {
  padding: 20px;
}
form {
  width: 100%;
  box-shadow: 0px 0px 20px #ddd;
  padding: 20px 50px;
  border-radius: 10px;
}
form * {
  width: 100%;
  margin: 25px 0;
  font-size: 15px;
  color: var(--text-color);
}
.f-title {
  font-size: 18px;
  font-family: MontserratBlack;
}
.email,
.massage,
.type {
  border: none;
  border-bottom: 2px solid var(--borders-color);
  padding: 10px 0;
}
.email:focus,
.massage:focus,
.type:focus {
  outline: none;
  border-bottom: 2px solid var(--orange-color);
}
.type {
  background: white;
}
.type option {
  background: white;
  padding: 10px 0;
  color: var(--text-color);
}
label {
  margin-bottom: 0px;
}
.btn-send {
  background: var(--orange-color);
  width: max-content;
  padding: 6px 40px;
  border-radius: 30px;
  color: var(--grey-color);
  border: none;
}
.right {
  padding: 50px;
  padding-right: 0;
}
.contact {
  border-bottom: 2px solid var(--borders-color);
}
.contact div {
  padding: 25px 0;
  font-size: 15px;
  color: var(--text-color);
}
.contact .mail {
  padding-bottom: 50px !important;
}
.contact img {
  height: 18px;
  margin-right: 10px;
}
.social {
  padding: 50px 0;
}
.social svg {
  margin-right: 30px;
  height: 20px;
  width: 20px;
}
@media only screen and (max-width: 992px) {
  .container {
    padding: 0 20px;
  }
  .title {
    font-size: 23px;
    padding: 35px 0;
  }
  .title-name {
    font-size: 43px;
    line-height: 3rem;
  }
  form {
    padding: 5px 30px;
  }
  form * {
    font-size: 14px;
  }
  .btn-send {
    padding: 7px 45px;
  }
  .email,
  .massage,
  .type {
    margin: 20px 0;
  }
  .right {
    padding: 10px;
  }
  .contact div {
    font-size: 18px;
  }
  .contact img {
    height: 22px;
  }
  .social svg {
    width: 25px;
    height: 25px;
  }
}
</style>
