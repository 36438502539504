<template>
  <div class="le-card">
    <div class="le-img">
      <router-link :to="'event'">
        <img :src="getImgUrl(event.img)" alt="" />
      </router-link>
    </div>
    <span><i class="fas fa-calendar-alt"></i> {{ event.date }}</span>
    <p>{{ event.title }}</p>
    <btn Text="Read more" class="btno" theme="sub" link="event"></btn>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: {
    btn: Button,
  },
  props: ["event"],
  methods: {
    getImgUrl(pic) {
      return require(`@/assets/${pic}`);
    },
  },
};
</script>

<style scoped>
.le-card {
  display: inline-block;
  padding: 10px;
  padding-top: 50px;
  transition: 700ms;
  clip-path: inset(0% 0% 10% 0%);
}
.le-img {
  width: 100%;
}
.le-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 18px;
  box-shadow: 1px 1px 1px #eee;
}
.le-card span {
  margin: 0 auto;
  display: block;
  width: max-content;
  font-size: 10px;
  color: #b7b7b7;
  margin-top: 15px;
}
.le-card span svg {
  width: 13px;
  padding-right: 1px;
  filter: invert(60%);
}
.le-card p {
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: var(--text-color);
  margin: 5px 0 10px;
  word-wrap: break-word;
  line-height: 1.1rem;
}
.le-card .btno {
  visibility: hidden;
  margin: 0 auto;
  width: max-content;
  transition: 200ms;
  font-size: 1;
}

.le-card:hover {
  transform: translateY(-35px);
  clip-path: inset(0% 0% 0% 0%);
}
.le-card:hover .btno {
  visibility: visible;
}
</style>
