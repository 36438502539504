<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    Footer,
    Header,
  },
};
</script>

<style>
@font-face {
  font-family: Montserrat;
  src: url("./assets/Montserrat-Regular.ttf");
}

@font-face {
  font-family: MontserratMedium;
  src: url("./assets/Montserrat-Medium.ttf");
}

@font-face {
  font-family: MontserratBold;
  src: url("./assets/Montserrat-Bold.ttf");
}

@font-face {
  font-family: MontserratBoldEx;
  src: url("./assets/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: MontserratBlack;
  src: url("./assets/Montserrat-Black.ttf");
}
.lg {
  color: var(--text-color);
  font-size: 14px;
  line-height: 1.4;
}
* {
  font-family: Montserrat;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: black;
}
:root {
  --text-color: black;
  --orange-color: #ffff00;
  --grey-color: #656565;
  --borders-color: #e9e9e9;
  --fs-header: 13.5px;
}
</style>
