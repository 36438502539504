<template>
  <div>
    <a class="contacto" :href="link" :class="{ sub: theme === 'sub' }"
      >{{ Text }} <img :src="theme === 'sub' ? orange : white"
    /></a>
  </div>
</template>

<script>
export default {
  name: "button",
  props: ["Text", "theme", "link"],
  data() {
    return {
      white: this.getImgUrl("Arrow.png"),
      orange: this.getImgUrl("Arrow-o.png"),
    };
  },
  methods: {
    getImgUrl(pic) {
      return require(`@/assets/${pic}`);
    },
  },
};
</script>

<style scoped>
.sub {
  color: var(--orange-color) !important;
  background-color: white !important;
  border: var(--orange-color) solid 1px;
  font-size: 13px;
  padding: 6px 20px !important;
}
.contacto {
  border-radius: 30px;
  padding: 10px 25px;
  background-color: var(--orange-color);
  color: var(--grey-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: max-content;
  text-decoration: none;
  cursor: pointer;
}
.contacto * {
  display: inline;
}

.contacto img {
  width: 13px !important;
  margin-left: 6px;
}

.contacto:hover {
  -webkit-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
}
</style>
