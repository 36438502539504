<template>
  <ul>
    <i class="fas fa-home"></i>
    <li v-for="link in links" :key="link.name">
      <router-link :to="link.path" :class="link.active ? 'a' : ''">
        {{ link.name }}
      </router-link>
      <div class="arrow" v-if="!link.active"></div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["links"],
  data() {
    return {
      //   links: [
      //     { name: "Home", path: "/" },
      //     { name: "Events", path: "/events" },
      //     { name: "Product", path: "/product" },
      //   ],
    };
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0px !important;
}
svg {
  width: 14px !important;
  padding-top: 4px;
}
li {
  display: inline-block;
  padding: 0 5px;
}
li a {
  padding-right: 5px;
  text-decoration: none;
  font-size: 13px;
  color: var(--text-color);
}
.arrow {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.a {
  color: var(--grey-color);
}
</style>
