<template>
  <div class="container p-lg-0">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>
        <img src="../assets/logo-2.png" class="logo" />
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto navo">
          <li class="nav-item">
            <!-- <a class="nav-link" href="#"
              >Home
              </a> -->
            <router-link to="/">
              Home
              <div class="line"></div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="aboutus">
              About Us
              <div class="line"></div>
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Products
              <div class="line"></div>
            </a>
            <ul class="dropdown-menu ddmo" aria-labelledby="navbarDropdown">
              <li><router-link to=""> Orthopedics</router-link></li>
              <li><router-link to=""> Neurosurgery</router-link></li>
              <li>
                <router-link to=""> Dental</router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link to="allevents">
              Events
              <div class="line"></div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="gallery">
              Gallery
              <div class="line"></div>
            </router-link>
          </li>
          <li class="nav-item contact">
            <router-link to="contactus"><btn Text="Contact"></btn></router-link>
          </li>
          <li class="">
            <div class="search-box" ref="searchbox">
              <input type="text" />
            </div>
            <img
              :src="
                searchLogo
                  ? require(`@/assets/Search.png`)
                  : require(`@/assets/Search-c.png`)
              "
              alt=""
              class="search-logo"
              @click="open_searchbox"
            />
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import Button from "../components/comp/Button.vue";
export default {
  name: "Header",
  components: {
    Btn: Button,
  },
  data() {
    return {
      searchLogo: true,
      a: "../assets/Search.png",
    };
  },
  methods: {
    open_searchbox() {
      this.$refs.searchbox.classList.toggle("openedSearch");
      this.searchLogo = !this.searchLogo;
    },
  },
};
//
// });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-size: var(--fs-header) !important;
  color: black !important;
}
.navo {
  align-items: center;
}
.navo li {
  margin-left: 18px;
  position: relative;
  cursor: pointer;
}
.navo li a {
  text-decoration: none;
  padding: 8px;
  display: block;
}
.line {
  position: absolute;
  width: 100%;
  border-bottom: 3px solid var(--orange-color);
  top: 54px;
  left: 0px;
  display: none;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.contact {
  transform: scaleY(0.85);
}
.container {
  padding: 0px;
}
@media only screen and (max-width: 992px) {
  .container {
    padding: 0 25px !important;
  }
  .nav-item {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
  .ddmo {
    width: 100%;
    text-align: center;
  }
  .ddmo li {
    margin: 0;
  }
  .contact {
    width: min-content;
    margin: auto !important;
  }
}
@media only screen and (min-width: 992px) {
  .nav-item:hover > a {
    color: var(--grey-color) !important;
    cursor: pointer;
  }
  .navo li:hover .line {
    display: block;
  }
  .ddmo {
    position: absolute;
    padding: 10px 7px;
    top: 54px;
    left: -33px;
    border-radius: 10px;
    border: none;
    -webkit-box-shadow: 0 0 3px #d5d5d5;
    box-shadow: 0 0 3px #d5d5d5;
  }
  .ddmo li {
    line-height: 2rem;
    margin-left: 0;
    cursor: pointer;
  }
  .ddmo li:hover a {
    color: var(--orange-color);
  }
}

.search-logo {
  width: 20px !important;
}

.navbar {
  border-bottom: 2px solid var(--borders-color);
}

.navbar img {
  max-height: 50px;
  width: 50px;
}

.navbar-toggler {
  padding: 0px;
  margin-right: 5px;
  box-shadow: 0 0 0 0 !important;
}
.navbar-toggler svg {
  width: 20px;
  height: 35px;
  transform: scaleY(0.8);
}
.navbar-brand {
  display: flex;
}
.search-box {
  width: 0px;
  height: 25px;
  display: inline-block;
  visibility: hidden;
  transition: 500ms;
}
.search-box input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2px solid var(--borders-color);
}
.openedSearch {
  width: 150px;
  visibility: visible;
}
/*# sourceMappingURL=style.css.map */
</style>
