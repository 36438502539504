<template>
  <div class="container px-lg-3 px-4">
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imags"
      :index="index"
      @hide="handleHide"
    >
      <template v-slot:prev-btn="{ prev }">
        <!-- <button @click="prev">show the prev</button> -->
        <div class="prev" @click="prev"></div>
      </template>

      <template v-slot:next-btn="{ next }">
        <!-- <button @click="next">show the next</button> -->
        <div class="next" @click="next"></div>
      </template>
    </vue-easy-lightbox>

    <div class="title">
      <div class="title-name">
        <span class="title-name tns">our</span> products
      </div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'Products', path: '/products', active: 'true' },
        ]"
      ></breadcrumbs>
    </div>
    <div class="contant row">
      <div class="open-tree" @click="openTree">
        <div class="ot-arrow">
          <i class="fas fa-arrow-left" style="color: white"></i>
        </div>
      </div>
      <div class="treec col-lg-4 col-12" ref="tree">
        <tree-view :node="root"></tree-view>
      </div>
      <div class="main col ms-auto px-0">
        <div class="search">
          <img src="../../assets/Search.png" alt="" />
          <input type="text" placeholder="Search by category or product name" />
        </div>
        <div class="products row">
          <product-card
            v-for="(prod, index) in products"
            :key="index"
            :id="prod"
            class="col-lg-4 col-6"
          ></product-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import ProductCard from "../comp/ProductCard.vue";
import TreeView from "../comp/TreeView.vue";

export default {
  components: { Breadcrumbs, ProductCard, TreeView },
  data() {
    return {
      products: [
        {
          name: "Calcaneus plates",
          img: "prod.png",
        },
        {
          name: "distal humeries locking y plates",
          img: "prod.png",
        },
        {
          name: "Calcaneus plates",
          img: "prod.png",
        },
        {
          name: "distal humeries locking y plates",
          img: "prod.png",
        },
        {
          name: "Calcaneus plates",
          img: "prod.png",
        },
        {
          name: "distal humeries locking y plates",
          img: "prod.png",
        },
        {
          name: "Calcaneus plates",
          img: "prod.png",
        },
        {
          name: "distal humeries locking y plates",
          img: "prod.png",
        },
      ],
      root: {
        child: [
          {
            name: "Dentil",
            child: [
              {
                name: "Implant Solution",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more 3",
                  },
                ],
              },
              {
                name: "Prosthetic Soliton",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
            ],
          },
          {
            name: "Neurosergery",
            child: [
              {
                name: "carnioplasty",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Kyphoplasty",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
            ],
          },
          {
            name: "Orthopedics",
            child: [
              {
                name: "Bone Cement",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Drill/Saw",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Graft",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Cement",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Drill/Saw",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Graft",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Cement",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Drill/Saw",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Graft",
                child: [
                  {
                    name: "more 1",
                    actice: true,
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    openTree() {
      this.$refs.tree.classList.toggle("active-tree");
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #1b1b1b;
}

.title {
  padding: 90px 0 50px;
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  margin-bottom: 10px;
}
.contant {
  border-top: 2px solid var(--borders-color);
  padding-top: 10px;
}

.open-tree {
  display: none;
  height: 24px;
}
.ot-arrow {
  width: 24px;
  height: 100%;
  background: var(--orange-color);
  border-radius: 10%;
  margin-right: auto;
}
.ot-arrow svg {
  margin-top: 4px;
  margin-left: 4px;
  filter: invert(100%);
}

.treec {
  height: 800px;
  min-width: 250px;
  overflow-y: auto;
}
.search {
  width: 400px;
  border-bottom: 2px solid var(--borders-color);
  margin-left: auto;
}
.search img {
  width: 35px;
  padding: 15px;
  padding-left: 0;
  filter: opacity(50%);
}
.search input {
  height: 40px;
  border: none;
  font-size: 13px;
  width: calc(100% - (35px + 15px));
  font-family: Montserrat;
  color: #444;
}
@media only screen and (max-width: 992px) {
  .title {
    font-size: 23px;
    padding: 35px 0;
  }
  .title-name {
    font-size: 43px;
    line-height: 3rem;
  }
  .tns {
    display: none;
  }
  .open-tree {
    display: block;
  }
  .treec {
    display: none;
    height: auto !important;
    margin-bottom: 30px;
  }
  .active-tree {
    display: block;
  }
  .search {
    width: 100%;
  }
  .search img {
    width: 30px;
    padding: 10px;
    padding-left: 0;
  }
}
</style>
