import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Product from "../components/products/product.vue"
import ProductsMain from "../components/products/ProductsMain.vue";
import Home from "../components/Home/Home.vue";
import Event from "../components/event/Event.vue";
import AllEvents from "../components/event/AllEvents.vue";
import Gallery from "../components/gallery/Gallery.vue";
import Album from "../components/gallery/Album.vue";
import ContactUs from "../components/ContactUs/ContactUs.vue";
import AboutUs from "../components/aboutus/AboutUs.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/products",
    name: "Products-main",
    component: ProductsMain,
  },
  {
    path: "/event",
    name: "Event",
    component: Event,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/album",
    name: "Album",
    component: Album,
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/allevents",
    name: "AllEvents",
    component: AllEvents,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
