<template>
  <div class="container px-lg-0">
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imageLightbox"
      :index="index"
      @hide="handleHide"
    >
      <template v-slot:prev-btn="{ prev }">
        <!-- <button @click="prev">show the prev</button> -->
        <div class="prev" @click="prev"></div>
      </template>

      <template v-slot:next-btn="{ next }">
        <!-- <button @click="next">show the next</button> -->
        <div class="next" @click="next"></div>
      </template>
    </vue-easy-lightbox>

    <div class="title">
      <div class="title-name">Event</div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'Events', path: '/events' },
          {
            name: 'Lorem ipsum dolor sit...',
            path: '/events',
            active: 'true',
          },
        ]"
      ></breadcrumbs>
    </div>
    <div class="row pt-lg-4 pt-2">
      <div class="main col-lg-8">
        <div class="event-title">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat,
          minus facilis
        </div>
        <div class="date">
          <img src="../../assets/calendar.png" alt="" />
          25 june 2021
        </div>
        <div class="img">
          <img src="../../assets/5.jpg" alt="" />
        </div>
        <p class="para">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat,
          minus facilis recusandae perspiciatis vel unde aperiam dolore
          reprehenderit perferendis non, Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Neque, voluptates fugiat natus modi sequi ad maiores
          alias tenetur distinctio aliquam temporibus rerum beatae repellat
          sapiente nam voluptatibus dignissimos laudantium quaerat!
        </p>
        <div class="more-img">
          <div class="mi-title">More Images</div>
          <div
            class="mi-img"
            v-for="(img, index) in moreImages10"
            :key="index"
            @click="showMultiple(index)"
          >
            <img :src="require(`@/assets/${img}`)" alt="" />
            <div
              class="mi-full-text"
              v-if="(moreImagesGrater != 0) & (index === 9)"
            >
              + {{ moreImagesGrater }}
            </div>
          </div>
        </div>
      </div>
      <div class="related col-lg-4">
        <div class="r-title">Related News</div>
        <news-card></news-card>
        <news-card></news-card>
        <news-card></news-card>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "./NewsCard.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";

import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js";
import "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css";

export default {
  name: "event",
  components: {
    NewsCard: NewsCard,
    Breadcrumbs: Breadcrumbs,
    VueEasyLightbox,
  },
  data() {
    return {
      moreImages: [
        "prod.png",
        "prod.png",
        "prod.png",
        "prod.png",
        "prod.png",
        "prod.png",
        "prod.png",
        "1.jpg",
        "1.jpg",
        "prod.png",
        "1.jpg",
        "prod.png",
        "prod.png",
        "1.jpg",
        "prod.png",
        "prod.png",
        "prod.png",
      ],
      moreImagesGrater: 0,
      visible: false,
      index: 0,
    };
  },
  computed: {
    moreImages10() {
      return this.moreImages.filter((e, i) => {
        if (i >= 10) this.moreImagesGrater++;
        return i < 10;
      });
    },
    imageLightbox() {
      return this.moreImages.map((e) => {
        return require(`@/assets/${e}`);
      });
    },
  },
  methods: {
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showMultiple(a) {
      this.index = a;
      this.show();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 45px 0 55px;
  border-bottom: 2px solid var(--borders-color);
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 38px;
  line-height: 3rem;
}
.main {
}
.event-title {
  font-size: 25px;
  font-family: MontserratBoldEx;
}
.date {
  color: grey;
  font-size: 15px;
  padding-bottom: 15px;
}
.date img {
  width: 15px;
  padding-right: 4px;
}
.img {
  width: 100%;
}
.img img {
  border-radius: 15px;
  width: 100%;
}
.para {
  font-size: 17px;
  color: var(--text-color);
  padding: 15px 0;
  margin: 0px;
}
.more-img {
  display: grid;
  grid-gap: 10px;
}
.mi-title {
  font-size: 20px;
  font-family: MontserratBold;
  padding-bottom: 10px;
  grid-column-start: 1;
  grid-column-end: 6;
}
.mi-img {
  border: 2px solid var(--borders-color);
  border-radius: 10px;
  position: relative;
  padding: 3px;
}
.mi-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* .mi-full {
  opacity: 0.3;
} */
.mi-full-text {
  position: absolute;
  width: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 101%;
  height: 101%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000a;
  border-radius: 7px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  font-family: MontserratBoldEx;
}
.related {
}
.r-title {
  font-size: 20px;
  font-family: MontserratBoldEx;
  border-bottom: 2px solid;
  border-image: linear-gradient(
      to right,
      var(--orange-color) 41%,
      var(--borders-color) 25%
    )
    1;
  padding: 10px 0 15px;
}
@media only screen and (max-width: 992px) {
  .container {
    padding: 0 25px !important;
  }
  .title {
    padding-top: 35px;
  }
  .title-name {
    font-size: 43px;
  }
  .para {
    font-size: 14px;
  }
  .mi-title {
    grid-column-end: 3;
  }
  .mi-img {
    height: 40vw;
  }
}

/*       lightbox        */
.vel-img-wrapper {
  position: relative !important;
}
.prev {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(-45deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 94%;
}
.next {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(135deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 2%;
}
@media only screen and (max-width: 992px) {
  .prev {
    left: 89%;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
  .next {
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
}
/*       lightbox   end     */
</style>
