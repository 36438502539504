<template>
  <div>
    <router-link to="product" style="text-decoration: none">
      <div class="bordero">
        <img :src="require(`@/assets/${id.img}`)" alt="" />
        <p class="lg">{{ id.name }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "productCard",
  props: ["id"],
};
</script>

<style scoped>
.bordero {
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
}
div p {
  font-family: MontserratMedium;
  text-align: center;
  padding-top: 10px;
  text-transform: capitalize;
  font-size: 16px;
  height: 55px;
  max-height: 55px;
  overflow: hidden;
}

div img {
  height: 170px;
  width: 100%;
  object-fit: contain;
}

.bordero:hover {
  /* -webkit-transform: translateY(-3px);
  transform: translateY(-3px); */
  -webkit-box-shadow: 1px 3px 15px #ddd;
  box-shadow: 1px 3px 15px #ddd;
}
</style>
