<template>
  <div class="container px-lg-3 px-4">
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imags"
      :index="index"
      @hide="handleHide"
    >
      <template v-slot:prev-btn="{ prev }">
        <!-- <button @click="prev">show the prev</button> -->
        <div class="prev" @click="prev"></div>
      </template>

      <template v-slot:next-btn="{ next }">
        <!-- <button @click="next">show the next</button> -->
        <div class="next" @click="next"></div>
      </template>
    </vue-easy-lightbox>

    <div class="title row">
      <div class="right col-lg-4 mx-lg-0 col-12 mx-sm-auto">
        <img src="../../assets/prod.png" alt="" />
      </div>
      <div class="left col-lg-6 mx-lg-0 col-12 mx-sm-auto">
        <div class="title-name">subtion knee spacer</div>
        <breadcrumbs
          :links="[
            { name: 'Home', path: '/' },
            { name: 'Products', path: '/products' },
            { name: 'Orthopedics', path: '/products' },
            { name: 'Spacers', path: '/products' },
            { name: 'Subition knee spacer', path: '/products', active: 'true' },
          ]"
        ></breadcrumbs>
      </div>
    </div>
    <div class="contant row">
      <div class="open-tree" @click="openTree">
        <div class="ot-arrow">
          <i class="fas fa-arrow-left" style="color: white"></i>
        </div>
      </div>
      <div class="treec col-lg-4 col-12" ref="tree">
        <tree-view :node="root"></tree-view>
      </div>
      <div class="info col">
        <div class="name">Subtion knee spacer</div>
        <strong>subtion knee spacer</strong>
        <p class="lg">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, iste
          recusandae illum maiores eius iure dignissimos quae tempora
          exercitationem odit in, minima aliquid, ullam corrupti neque pariatur?
          Voluptatem, dolores eaque. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Laboriosam sint nulla esse tenetur repellendus,
          beatae sunt sequi minus tempora reiciendis itaque placeat expedita
          aspernatur incidunt. Omnis rerum ullam reiciendis ea!
        </p>
        <ul>
          <li class="lg">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae
            mollitia sit eaque voluptate delectus tenetur p
          </li>
          <li class="lg">subtion knee spacer</li>
          <li class="lg">
            subtion knee spacer subtion knee spacer subtion knee spacer
          </li>
          <li class="lg">subtion knee spacer</li>
          <li class="lg">subtion knee spacer subtion knee spacer</li>
          <li class="lg">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero ab
            neque sint repudiandae natus aliquid hic quae necessitatibus,
            inventore id maiores ratione, accusamus, error dolor! Eveniet odio
            sit nemo quas?
          </li>

          <li class="lg">subtion knee spacer subtion knee spacer</li>
        </ul>
        <div class="name" style="padding-left: 15px">More images</div>
        <div class="photos row py-2 px-3">
          <div
            class="col-lg-3 col-6 p-2"
            v-for="(img, index) in imags"
            :key="index"
          >
            <div class="photos-border">
              <img :src="img" alt="" @click="showMultiple(index)" />
            </div>
          </div>
          <!-- <div class="col-lg-3 col-6 p-2">
            <div class="photos-border">
              <img src="../../assets/prod.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-6 p-2">
            <div class="photos-border">
              <img src="../../assets/prod.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-6 p-2">
            <div class="photos-border">
              <img src="../../assets/prod.png" alt="" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="releted">
      <div class="releted-name">
        Related products
        <div class="line"></div>
      </div>
      <hr />
      <div class="up row">
        <product-card
          v-for="related in relatedProducts"
          :key="related.name"
          :id="related"
          class="col-lg-3 col-6 p-1"
        ></product-card>
        <!-- <div class="col-lg-3 col-6 p-4">
          <div class="releted-border">
            <img src="../../assets/prod.png" alt="" />
            <p class="lg">Calcaneus plates</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 p-4">
          <div class="releted-border">
            <img src="../../assets/prod.png" alt="" />
            <p class="lg">distal humeries locking y plates</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 p-4">
          <div class="releted-border">
            <img src="../../assets/prod.png" alt="" />
            <p class="lg">distal humeries locking y plates</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 p-4">
          <div class="releted-border">
            <img src="../../assets/prod.png" alt="" />
            <p class="lg">distal humeries locking y plates</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import treeview from "vue3-treeview";
import ProductCard from "../comp/ProductCard.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js";
import "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css";
import TreeView from "../comp/TreeView.vue";

export default {
  components: {
    ProductCard: ProductCard,
    TreeView,
    Breadcrumbs: Breadcrumbs,
    VueEasyLightbox,
  },
  data: function () {
    return {
      modeBool: false,
      config: {
        roots: ["Dental", "Neurosurgery", "Orthopedic"],
        keyboardNavigation: false,
        dragAndDrop: false,
        checkboxes: false,
        editable: true,
        // editing: "Dental",
        editableClass: "aaa",
        disabled: false,
        padding: 10,
      },
      root: {
        child: [
          {
            name: "Dentil",
            child: [
              {
                name: "Implant Solution",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more 3",
                  },
                ],
              },
              {
                name: "Prosthetic Soliton",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
            ],
          },
          {
            name: "Neurosergery",
            child: [
              {
                name: "carnioplasty",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Kyphoplasty",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
            ],
          },
          {
            name: "Orthopedics",
            child: [
              {
                name: "Bone Cement",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Drill/Saw",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Graft",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Cement",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Drill/Saw",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Graft",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Cement",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                ],
              },
              {
                name: "Drill/Saw",
                child: [
                  {
                    name: "more 1",
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
              {
                name: "Bone Graft",
                child: [
                  {
                    name: "more 1",
                    actice: true,
                  },
                  {
                    name: "more 2",
                  },
                  {
                    name: "more e",
                  },
                ],
              },
            ],
          },
        ],
      },
      relatedProducts: [
        {
          name: "Calcaneus plates",
          img: "prod.png",
        },
        {
          name: "distal humeries locking y plates",
          img: "prod.png",
        },
        {
          name: "Calcaneus plates",
          img: "prod.png",
        },
        {
          name: "distal humeries locking y plates",
          img: "prod.png",
        },
      ],
      imgs: ["prod.png", "1.jpg", "prod.png", "1.jpg"],
      visible: false,
      index: 0,
    };
  },
  methods: {
    changeMode() {
      this.modeBool = !this.modeBool;
      this.config.checkMode = this.modeBool ? 0 : 1;
    },
    getImgUrl(pic) {
      return require(`@/assets/${pic}`);
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showMultiple(a) {
      this.index = a;
      this.show();
    },
    openTree() {
      this.$refs.tree.classList.toggle("active-tree");
    },
  },
  computed: {
    imags() {
      var a = [];
      this.imgs.map((e) => {
        a.push(this.getImgUrl(e));
      });
      return a;
    },
  },
  name: "Footer",
};
</script>

<style scoped>
* {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #1b1b1b;
}

.container .title {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  direction: rtl;
  color: #262626;
}

.container .title .left {
  max-width: 500px;
  direction: ltr;
}

.container .title .left .title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  padding-top: 62px;
  margin-bottom: 10px;
}

.container .title .right {
  padding: 5px;
  display: flex;
}

.container .title .right img {
  width: 350px;
  margin: 0 auto;
}

.container .contant {
  padding-top: 16px;
  border-top: 2px solid #e9e9e9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.open-tree {
  display: none;
  height: 24px;
}
.ot-arrow {
  width: 24px;
  height: 100%;
  background: var(--orange-color);
  border-radius: 10%;
  margin-right: auto;
}
.ot-arrow svg {
  margin-top: 4px;
  margin-left: 4px;
  filter: invert(100%);
}

.container .contant .treec {
  width: 30%;
  height: 800px;
  overflow-y: auto;
  min-width: 250px;
  margin-right: 70px;
}

.container .contant .info {
  width: 60%;
  word-spacing: -0.2rem;
}

.container .contant .info .name {
  padding: 15px 0;
  font-size: 20px;
  font-family: MontserratBoldEx;
}

.container .contant .info strong {
  text-transform: uppercase;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.4rem;
  padding-left: 5px;
  word-spacing: 0.2rem;
}

.container .contant .info p {
  margin-top: 3px;
  line-height: 1.2rem;
  padding-left: 5px;
}

.container .contant .info ul {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  list-style-position: outside;
  margin-left: 20px;
}

.container .contant .info ul li {
  list-style: disc;
  margin: 13px 0 0 18px;
}

/* .container .contant .info .photos {
  padding-left: 20px;
}

.container .contant .info .photos {
  width: 100%;
  padding: 25px;
} */

.container .contant .info .photos .photos-border {
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  display: inline-block;
}

.container .contant .info .photos div img {
  margin: auto;
  padding: 3px;
  width: 100%;
  height: 130px;
  object-fit: contain;
}

.container .releted {
  margin-top: 50px;
}

.container .releted .releted-name {
  font-size: 20px;
  font-family: MontserratBoldEx;
  position: relative;
}

.container .releted .releted-name .line {
  position: absolute;
  top: 44px;
  left: 0;
  width: 190px;
  border: 1px solid var(--orange-color);
}

.container .releted hr {
  color: #e9e9e9;
  height: 2px;
  margin: 14px 0;
}

/* .container .releted .up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-between;
} */

/* .container .releted .up div {
  padding: 0 25px;
} */
.container .releted .up div p {
  text-align: center;
  padding-top: 10px;
  text-transform: capitalize;
}

.container .releted .up div img {
  width: 100%;
}

.container .releted .up .releted-border:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 1px 3px 10px #999;
  box-shadow: 1px 3px 10px #999;
}

@media only screen and (max-width: 992px) {
  .container {
    padding: 0 35px !important;
  }
  .title-name {
    font-size: 45px !important;
  }
  .left {
    text-align: center;
  }
  .open-tree {
    display: block;
  }
  .treec {
    display: none;
    height: auto !important;
    margin-bottom: 30px;
  }
  .active-tree {
    display: block;
  }
  .container .contant .info ul {
    margin-left: 0;
  }
  .name {
    font-size: 18px !important;
    padding: 5px 0 5px 5px !important;
  }
  .photos > div {
    padding: 2px !important;
  }
}

/**       lightbox        */
.vel-img-wrapper {
  position: relative !important;
}
.prev {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(-45deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 94%;
}
.next {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(135deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 2%;
}
@media only screen and (max-width: 992px) {
  .prev {
    left: 89%;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
  .next {
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
}
/**       lightbox      end  */
/*# sourceMappingURL=style.css.map */
</style>
