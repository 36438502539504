<template>
  <div class="container px-lg-0 px-4">
    <!-- slaide -->
    <div class="links">
      <i class="fab fa-facebook-f"></i>
      <i class="fab fa-linkedin-in"></i>
      <i class="fab fa-instagram"></i>
      <i class="fab fa-twitter"></i>
    </div>

    <div id="slider" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#slider"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#slider"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#slider"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="slide">
            <img src="../../assets/prod.png" alt="" />
            <div class="left">
              <div class="slide-title">Z1 implants</div>
              <div class="slide-sub-title">Dental, implant solution</div>
              <btn :Text="'Detailes'" class="slide-button"></btn>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="slide">
            <img src="../../assets/prod.png" alt="" />
            <div class="left">
              <div class="slide-title">Z1 implants</div>
              <div class="slide-sub-title">Dental, implant solution</div>
              <btn :Text="'Detailes'" class="slide-button"></btn>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="slide">
            <img src="../../assets/prod.png" alt="" />
            <div class="left">
              <div class="slide-title">Z1 implants</div>
              <div class="slide-sub-title">Dental, implant solution</div>
              <btn :Text="'Detailes'" class="slide-button"></btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="latest-products">
      <div class="title">Latest Products</div>
      <div class="chose-lp lg">
        <div @click="change_lp('Orthopedics')" class="o" ref="o">
          Orthopedics
        </div>
        <div @click="change_lp('Neurosurgery')" ref="n">Neurosurgery</div>
        <div @click="change_lp('Dental')" ref="d">Dental</div>
      </div>
      <div class="items-lp row">
        <product-card
          :id="prod"
          class="col-lg-3 mb-4 col-6 lg"
          v-for="(prod, index) in latestProducdsOut"
          :key="index"
        ></product-card>
      </div>
      <btn Text="See All Products" class="c-btn" link="products"></btn>
    </div>
    <hr />
    <div class="latest-event">
      <div class="title">Latest Event</div>
      <p class="subtitle-le">
        Al-radwan seeking to be present Al-radwan seeking to be present
        Al-radwan seeking to be present
      </p>
      <div class="row">
        <event-card
          class="le-card col-lg-3 col-md-6 col-12"
          v-for="event in events"
          :key="event.title"
          :event="event"
        ></event-card>
        <!-- <div
          class="le-card col-lg-3 col-md-6 col-12"
          v-for="event in events"
          :key="event.title"
        >
          <div class="le-img">
            <router-link :to="'events'">
              <img :src="getImgUrl(event.img)" alt="" />
            </router-link>
          </div>
          <span><i class="fas fa-calendar-alt"></i> {{ event.date }}</span>
          <p>{{ event.title }}</p>
          <btn Text="Read more" class="btno" theme="sub" link="events"></btn>
        </div> -->
        <!-- <div class="le-card col-lg-3 col-md-6 col-12">
          <div class="le-img">
            <a href="event"><img src="../../assets/1.jpg" alt="" /></a>
          </div>
          <span><i class="fas fa-calendar-alt"></i> 25 june 2021</span>
          <p>Al razi hospital annul scientifecic fourm 2018</p>
          <btn Text="Read more" class="btno" theme="sub" link="'event"></btn>
        </div>
        <div class="le-card col-lg-3 col-md-6 col-12">
          <div class="le-img">
            <a href="event"><img src="../../assets/1.jpg" alt="" /></a>
          </div>
          <span> <i class="fas fa-calendar-alt"></i> 25 june 2021</span>
          <p>Al razi hospital annul scientifecic fourm 2018</p>
          <btn Text="Read more" class="btno" theme="sub" link="event"></btn>
        </div>
        <div class="le-card col-lg-3 col-md-6 d-lg-block d-none">
          <div class="le-img">
            <a href="event"><img src="../../assets/1.jpg" alt="" /></a>
          </div>
          <span> <i class="fas fa-calendar-alt"></i> 25 june 2021</span>
          <p>Al razi hospital annul scientifecic fourm 2018</p>
          <btn Text="Read more" class="btno" theme="sub" link="event"></btn>
        </div>
        <div class="le-card col-lg-3 col-md-6 d-lg-block d-none">
          <div class="le-img">
            <a href="event"><img src="../../assets/1.jpg" alt="" /></a>
          </div>
          <span><i class="fas fa-calendar-alt"></i> 25 june 2021</span>
          <p>Al razi hospital annul scientifecic fourm 2018</p>
          <btn Text="Read more" class="btno" theme="sub" link="event"></btn>
        </div> -->
      </div>
      <btn Text="See All News" class="c-btn" link="events"></btn>
    </div>
    <hr />
    <div class="our-values row">
      <div class="title">Our Values</div>
      <div class="item-ov col-lg-4 col-12">
        <img src="../../assets/Trustworthiness.png" />
        <div>Trustworthiness</div>
      </div>
      <div class="item-ov col-lg-4 col-6 c-ov">
        <img src="../../assets/Motivation.png" />
        <div>Motivation</div>
      </div>
      <div class="item-ov col-lg-4 col-6">
        <img src="../../assets/Innovation.png" />
        <div>Innovation</div>
      </div>
      <btn Text="About ALRADWAN" class="c-btn mt-4"></btn>
    </div>
  </div>
</template>

<script>
import ProductCard from "../comp/ProductCard.vue";
import Button from "../comp/Button.vue";
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import EventCard from "../comp/EventCard.vue";

export default {
  components: {
    ProductCard: ProductCard,
    Btn: Button,
    Breadcrumbs: Breadcrumbs,
    EventCard,
  },
  name: "Home",
  data() {
    return {
      url: "../../assets/prod.png",
      a: "a",
      latestProducds: [
        [
          { img: "prod.png", name: "Orthopedics" },
          { img: "1.jpg", name: "Orthopedics" },
          { img: "5.jpg", name: "Orthopedics" },
          { img: "1.jpg", name: "Orthopedics plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "1.jpg", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "5.jpg", name: "calcaneus plates" },
        ],
        [
          { img: "prod.png", name: "Neurosurgery" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
        ],
        [
          { img: "prod.png", name: "Dental" },
          { img: "prod.png", name: "Dental" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
          { img: "prod.png", name: "calcaneus plates" },
        ],
      ],
      latestProducdsOut: [
        { img: "prod.png", name: "Orthopedics" },
        { img: "1.jpg", name: "Orthopedics" },
        { img: "5.jpg", name: "Orthopedics" },
        { img: "1.jpg", name: "Orthopedics plates" },
        { img: "prod.png", name: "calcaneus plates" },
        { img: "1.jpg", name: "calcaneus plates" },
        { img: "prod.png", name: "calcaneus plates" },
        { img: "5.jpg", name: "calcaneus plates" },
      ],
      events: [
        {
          img: "prod.png",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
      ],
    };
  },
  methods: {
    change_lp(type) {
      if (type === "Orthopedics") {
        this.latestProducdsOut = this.latestProducds[0];
        this.$refs.o.classList.add("o");
        this.$refs.d.classList.remove("o");
        this.$refs.n.classList.remove("o");
      } else if (type === "Neurosurgery") {
        this.latestProducdsOut = this.latestProducds[1];
        this.$refs.n.classList.add("o");
        this.$refs.d.classList.remove("o");
        this.$refs.o.classList.remove("o");
      } else if (type === "Dental") {
        this.latestProducdsOut = this.latestProducds[2];
        this.$refs.d.classList.add("o");
        this.$refs.n.classList.remove("o");
        this.$refs.o.classList.remove("o");
      } else {
        this.latestProducdsOut = this.latestProducds[0];
      }
    },
    getImgUrl(pic) {
      return require(`@/assets/${pic}`);
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}
.links {
  position: absolute;
  left: -70px;
  top: 40px;
  z-index: 10;
}
@media only screen and (max-width: 992px) {
  .links {
    left: 25px;
  }
  .links svg {
    width: 15px !important;
    height: 15px !important;
  }
}
.links svg {
  width: 20px;
  height: 20px;
  margin-bottom: 30px;
  display: block;
  filter: invert(60%);
}
.slider {
  width: 100%;
  height: 300px;
  background-color: bisque;
}
hr {
  margin: 25px 0;
}
.title {
  text-align: center;
  font-family: MontserratBoldEx;
  font-size: 27px;
  letter-spacing: 0.03rem;
  color: #262626;
}
.c-btn {
  margin: 0 auto;
  width: min-content;
  font-size: 13px;
}
@media only screen and (max-width: 992px) {
  .title {
    font-size: 23px;
  }
}
/*   latest produc5ts parts style   */
.chose-lp {
  margin: 5px auto 40px;
  width: max-content;
}
.chose-lp div {
  margin: 0 30px;
  display: inline-block;
  padding: 10px 0;
  font-size: 16px;
  color: var(--text-color);
  font-family: MontserratMedium;
  cursor: pointer;
}
.o {
  color: var(--grey-color) !important;
  border-bottom: 2px solid var(--orange-color);
}

/* rsponsev */
@media only screen and (max-width: 992px) {
  .chose-lp div {
    font-size: 13px;
  }
  .chose-lp div:first-child {
    margin-left: 0;
  }
  .chose-lp div:last-child {
    margin-right: 0;
  }
}
/*   latest products parts style end  */
/*   latest events parts style   */
/*13289*/
.ltatest-evet {
}

.subtitle-le {
  margin: 0 auto;
  text-align: center;
  max-width: 450px;
  color: var(--text-color);
  font-size: 13px;
}

/* rsponsev */
@media only screen and (max-width: 992px) {
  .subtitle-le {
    font-size: 13px;
  }
  .le-card span {
    font-size: 13px;
  }
}

/*   latest events parts style end   */
/*   our values parts style   */
.our-values {
  align-items: center;
}
.item-ov {
  margin: 10px 0 20px;
  padding: 5px 0;
}
.item-ov img {
  width: 60px;
  padding: 10px;
  display: block;
  margin: 0 auto;
}
.item-ov div {
  width: min-content;
  display: block;
  margin: 0 auto;
  font-family: MontserratBold;
  font-size: 16px;
}
.c-ov {
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
}

/* responsev */
@media only screen and (max-width: 992px) {
  .c-ov {
    border: none;
  }
  .item-ov {
    margin: 20px 0;
  }
  .item-ov img {
    width: 40px;
    padding: 3px;
  }
}
/*   our values parts style end   */

/*                slider          */
#slider {
  margin-bottom: 20px;
}
.carousel-inner {
  height: 350px;
}
.carousel-item {
  height: 100%;
}
.slide {
  height: 100%;
  direction: rtl;
  display: flex;
  justify-content: space-between;
}
.slide img {
  padding-right: 60px;
}
.left {
  height: 100%;
  padding: 50px 0;
  direction: ltr;
}
.slide-title {
  font-size: 60px;
  font-family: MontserratBlack;
  line-height: 4.5rem;
  color: #262626;
}
.slide-sub-title {
  font-size: 20px;
  color: var(--text-color);
}
.slide-button {
  padding: 30px 0;
}
.carousel-indicators button {
  width: 5px;
  height: 5px;
  background-color: #d2d2d2;
  border: 3px solid #d2d2d2;
  border-radius: 20px;
}
/* rsponsev */
@media only screen and (max-width: 992px) {
  #slider {
    padding: 0 10px;
  }
  .carousel-inner {
    height: auto;
  }
  .slide {
    display: block;
  }
  .slide img {
    width: 80%;
    padding: 0;
    margin: 0 auto;
    display: block;
  }
  .left {
    padding: 0;
    padding-bottom: 30px;
    text-align: center;
  }
  .slide-title {
    font-size: 45px;
    line-height: 4rem;
  }
  .slide-sub-title {
    font-size: 14px;
  }
  .slide-button {
    width: max-content;
    margin: 0 auto;
    font-size: 12px;
  }
  .carousel-indicators button {
    width: 2px;
    height: 2px;
  }
}
/*         slider    end      */

.asd {
  display: block;
  margin: 100px auto;
  width: max-content;
  position: relative;
}
.vel-img-wrapper {
  position: relative !important;
}
.prev {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(-45deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 94%;
}
.next {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(135deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 2%;
}
@media only screen and (max-width: 992px) {
  .prev {
    left: 89%;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
  .next {
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
}
</style>
