<template>
  <div class="container">
    <div class="title">
      <div class="title-name">Events</div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'Events', path: '/allevents', active: 'true' },
        ]"
      ></breadcrumbs>
    </div>
    <div class="main">
      <event-card
        :event="event"
        v-for="event in events"
        :key="event"
      ></event-card>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import EventCard from "../comp/EventCard.vue";
export default {
  components: {
    Breadcrumbs,
    EventCard,
  },
  data() {
    return {
      events: [
        {
          img: "prod.png",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "prod.png",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
        {
          img: "1.jpg",
          date: "25 june 2020",
          title: "Al razi hospital annul scientifecic fourm 2018",
        },
      ],
    };
  },
  computed: {
    eventImgs() {
      var a = [];
      return this.events.map((e) => {
        var x = { img: null, name: String };
        x.name = e.name;
        x.img = require(`@/assets/${e.img}`);
        return x;
      });
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #1b1b1b;
}

.title {
  padding: 90px 0 50px;
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  margin-bottom: 10px;
}
.main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 2px solid var(--borders-color);
  padding-top: 30px;
}
.event {
  width: 100%;
  padding: 0 15px;
}
@media only screen and (max-width: 992px) {
  .container {
    padding: 0 20px;
  }
  .title {
    font-size: 23px;
    padding: 35px 0;
  }
  .title-name {
    font-size: 43px;
    line-height: 3rem;
  }
  .main {
    grid-template-columns: 1fr;
  }
  .event {
    padding: 0;
    height: 400px;
  }
}
</style>
