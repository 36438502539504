<template>
  <div>
    <div
      class="node"
      @click="if (depth == 2) expand = !expand;"
      :class="node.actice ? 'actice' : ''"
    >
      <div v-if="depth == 1" class="logo">
        <img :src="logos" alt="" />{{ node.name }}
      </div>
      <div v-else-if="depth == 2" class="logoExpand">
        <img src="../../assets/expand.png" alt="" />
        {{ node.name }}
      </div>
      <div v-else-if="depth == 3" class="space">
        <router-link to="product" style="text-decoration: none">{{
          node.name
        }}</router-link>
      </div>
    </div>
    <tree-view
      v-for="(child, i) in node.child"
      :key="child"
      :node="child"
      :depth="depth + 1"
      :index="i"
      v-if="expand || depth == 0 || depth == 1"
    >
    </tree-view>
  </div>
</template>

<script>
export default {
  name: "treeView",
  props: {
    node: Object,
    depth: {
      type: Number,
      default: 0,
    },
    index: Number,
  },
  data() {
    return {
      expand: false,
      level: this.index + 1,
    };
  },
  computed: {
    logos() {
      //   this.level += 1;
      console.log(this.level);
      if (this.level == 1) {
        return require(`@/assets/dental.png`);
      } else if (this.level == 2) {
        return require(`@/assets/naurosergary.png`);
      } else {
        return require(`@/assets/orthopedics.png`);
      }
    },
  },
};
</script>

<style scoped>
* {
  color: var(--text-color);
}
.actice * {
  color: var(--orange-color);
}
.node {
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 20px;
}
.node *:hover {
  color: inherit;
}
.logo {
  margin-top: 20px;
}
.logo img {
  height: 20px;
  margin-right: 7px;
}
.logoExpand {
  margin-top: 12px;
  margin-left: 30px;
}
.logoExpand img {
  height: 7px;
  margin-right: 5px;
}
.space {
  margin-top: 10px;
  margin-left: 60px;
  display: list-item;
}
</style>
