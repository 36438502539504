<template>
  <div class="container">
    <div class="title">
      <div class="title-name">Gallery</div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'Gallery', path: '/gallery', active: 'true' },
        ]"
      ></breadcrumbs>
    </div>
    <div class="main">
      <div class="album" v-for="(album, index) in albumImgs" :key="index">
        <router-link to="album">
          <div class="a-img">
            <img :src="album.img" alt="" />
          </div>
        </router-link>
        <div class="a-name">{{ album.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      albums: [
        { img: "g1.jpg", name: "album name" },
        {
          img: "5.jpg",
          name: "album asd ad asda sd asd asd asd asdsa sda ssda sda da d",
        },
        { img: "g1.jpg", name: "album name" },
        { img: "g2.jpg", name: "album name" },
        { img: "g1.jpg", name: "album name" },
        { img: "g2.jpg", name: "album name" },
      ],
    };
  },
  computed: {
    albumImgs() {
      var a = [];
      return this.albums.map((e) => {
        var x = { img: null, name: String };
        x.name = e.name;
        x.img = require(`@/assets/${e.img}`);
        return x;
      });
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #1b1b1b;
}

.title {
  padding: 90px 0 50px;
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  margin-bottom: 10px;
}
.main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 2px solid var(--borders-color);
  padding-top: 30px;
}
.album {
  width: 100%;
  padding: 0 15px;
}
.a-img {
  cursor: pointer;
  width: 100%;
  height: 80%;
}
.a-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.a-name {
  margin: 15px 0;
  text-align: center;
  font-family: MontserratBold;
  text-transform: capitalize;
  line-height: 25px;
  height: 25px;
  overflow: hidden;
  box-sizing: content-box;
  font-size: 16px;
}
@media only screen and (max-width: 992px) {
  .container {
    padding: 0 20px;
  }
  .title {
    font-size: 23px;
    padding: 35px 0;
  }
  .title-name {
    font-size: 43px;
    line-height: 3rem;
  }
  .main {
    grid-template-columns: 1fr;
  }
  .album {
    padding: 0;
    height: 400px;
  }
  .a-name {
    font-size: 20px;
  }
}
</style>
