<template>
  <div class="container px-lg-0">
    <div class="big-title">
      <div class="title-name">About us</div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'About Us', path: '/aboutus', active: 'true' },
        ]"
      ></breadcrumbs>
    </div>
    <div class="contant">
      <div class="top">
        <div class="t-top title">
          About our <br />
          company
        </div>
        <p>
          AL RADWAN Lorem ipsum dolor, sit amet consectetur adipisicing elit. In
          laudantium libero sapiente corrupti quos quibusdam maxime deserunt
          nisi odio eum animi dolore tempore ipsum neque nihil, sequi quis
          consequatur voluptatum!
        </p>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. In
          laudantium libero sapiente corrupti quos quibusdam maxime deserunt
          nisi odio eum animi dolore tempore ipsum neque nihil, sequi quis
          consequatur voluptatum!
        </p>
      </div>
      <div class="right">
        <div class="t-right">Compay Profile</div>
        <div class="profile">
          <img src="../../assets/vision.png" alt="" />
          <div>
            <div class="t-profile">Vision</div>
            <p class="p-profile">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat,
              quasi, non voluptatem aspernatur
            </p>
          </div>
        </div>
        <div class="profile">
          <img src="../../assets/mission.png" alt="" />
          <div>
            <div class="t-profile">Vision</div>
            <p class="p-profile">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat,
              quasi, non voluptatem aspernatur dolore inventore cupiditate est
              rerum aliquid molestias, consectetur omnis! Nihil a voluptatum
              dolore neque rerum expedita iste?
            </p>
          </div>
        </div>
        <div class="profile">
          <img src="../../assets/values.png" alt="" />
          <div>
            <div class="t-profile">Vision</div>
            <p class="p-profile">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat,
              quasi, non voluptatem
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="left">
      <div class="t-left title">
        Brief Company <br />
        History
      </div>
      <p class="p-left">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam fuga
        sequi velit inventore minima numquam consequuntur sed repellat aperiam!
        Consequatur dignissimos in maxime voluptatum! Aperiam odio sapiente illo
        quas necessitatibus!Lorem
      </p>
      <p class="p-left">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
        accusamus tempore vero eum esse fugit nesciunt dolor quaerat. Incidunt
        architecto quos et deserunt exercitationem nesciunt non repellat soluta
        ea iusto. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        Excepturi molestiae, sunt assumenda illum voluptatem distinctio
        perspiciatis enim incidunt corporis numquam ducimus, veritatis
        exercitationem sit. Ea sunt quidem odio quae? Numquam! Lorem ipsum dolor
        sit amet consectetur, adipisicing elit. Possimus dolore labore, nesciunt
        voluptate facere deserunt illo rerum tenetur non ullam ex officiis sed
        earum quos repellat obcaecati qui placeat? Eaque! Lorem ipsum dolor, sit
        amet consectetur adipisicing elit. Aliquam fuga sequi velit inventore
        minima numquam consequuntur sed repellat aperiam! Consequatur
        dignissimos in maxime voluptatum! Aperiam odio sapiente illo quas
        necessitatibus!Lorem
      </p>
    </div>
    <div class="images">
      <div class="i-container">
        <img src="../../assets/g1.jpg" alt="" />
      </div>
      <div class="i-container">
        <img src="../../assets/g1.jpg" alt="" />
      </div>
      <div class="i-container">
        <img src="../../assets/1.jpg" alt="" />
      </div>
      <div class="i-container">
        <img src="../../assets/g1.jpg" alt="" />
      </div>
      <div class="i-container">
        <img src="../../assets/g1.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
export default {
  components: {
    Breadcrumbs,
  },
};
</script>

<style scoped>
* {
  font-size: 14px;
  color: #1b1b1b;
}
p {
  line-height: 1.3rem;
}

.big-title {
  padding: 90px 0 50px;
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  margin-bottom: 10px;
}
.contant {
  display: flex;
  border-top: 2px solid var(--borders-color);
  padding-top: 20px;
}
.title {
  font-size: 32px;
  font-family: MontserratBlack;
  border-left: 4px solid var(--orange-color);
  padding-left: 20px;
  margin: 20px 0;
  line-height: 2.4rem;
}
.top {
  flex: 3;
  padding-right: 40px;
  padding-bottom: 130px;
}
.t-top {
}

.right {
  flex: 2;
  padding-bottom: 10px;
}
.t-right {
  color: var(--grey-color);
  font-size: 24px;
  margin: 20px 0;
  font-family: MontserratBlack;
}
.profile {
  display: flex;
  padding: 10px 0;
  font-size: 13px;
}
.t-profile {
  font-size: 14px;
  font-family: MontserratBold;
}
.profile img {
  height: 50px;
  padding-right: 10px;
}

.left {
  position: relative;
}
.t-left {
  position: absolute;
  bottom: 101%;
}
.images {
  padding: 50px 0;
  display: flex;
}
.i-container {
  padding: 10px;
  height: 15vw;
  width: 20%;
}
.i-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
@media only screen and (max-width: 992px) {
  * {
    font-size: 13px;
  }
  .container {
    padding: 0 20px;
  }
  .big-title {
    font-size: 23px;
    padding: 35px 0;
  }
  .title-name {
    font-size: 43px;
    line-height: 3rem;
  }
  .contant {
    display: block;
  }
  .title {
    padding: 0;
    padding-left: 15px;
    font-size: 26px;
  }
  P {
    line-height: 1.15rem;
  }
  .top {
    padding: 0;
  }
  .t-right {
    margin: 30px 0 10px;
    font-size: 22px;
  }
  .profile {
    padding: 3px 0;
  }
  .left {
    margin-top: 110px;
  }
  .images {
    flex-wrap: wrap;
    padding-top: 10px;
  }
  .i-container {
    width: 50%;
    height: 40vw;
  }
}
</style>
