<template>
  <div class="container">
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="photoLightBox"
      :index="index"
      @hide="handleHide"
      class="aaa"
    >
      <template v-slot:prev-btn="{ prev }">
        <!-- <button @click="prev">show the prev</button> -->
        <div class="prev" @click="prev"></div>
      </template>

      <template v-slot:next-btn="{ next }">
        <!-- <button @click="next">show the next</button> -->
        <div class="next" @click="next"></div>
      </template>
    </vue-easy-lightbox>

    <div class="title">
      <div class="title-name">Album Name</div>
      <breadcrumbs
        :links="[
          { name: 'Home', path: '/' },
          { name: 'Gallery', path: '/gallery' },
          { name: 'Album Name', path: '/album', active: 'true' },
        ]"
      ></breadcrumbs>
    </div>
    <div class="main">
      <div class="photo" v-for="(photo, index) in photosLocal" :key="index">
        <div class="p-img" @click="showMultiple(index)">
          <img :src="photo.img" alt="" />
        </div>
        <div class="p-name" v-if="photo.name">{{ photo.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../comp/Breadcrumbs.vue";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js";
import "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css";

export default {
  components: {
    Breadcrumbs,
    VueEasyLightbox,
  },
  data() {
    return {
      photos: [
        { img: "g1.jpg", name: "" },
        { img: "g2.jpg", name: "photo name" },
        {
          img: "5.jpg",
          name: "photo asd ad asda sd asd asd asd asdsa sda ssda sda da d",
        },
        { img: "g1.jpg", name: "photo name" },
        { img: "g2.jpg" },
        { img: "g1.jpg", name: "" },
        { img: "g1.jpg", name: "photo name" },
        { img: "g1.jpg" },
        { img: "g2.jpg", name: "photo name" },
        { img: "g2.jpg", name: "photo name" },
        { img: "g1.jpg", name: "photo name" },
        { img: "g2.jpg", name: "photo name" },
      ],
      visible: false,
      index: 0,
    };
  },
  computed: {
    photosLocal() {
      var a = [];
      return this.photos.map((e) => {
        var x = { img: null, name: String };
        x.name = e.name;
        x.img = require(`@/assets/${e.img}`);
        return x;
      });
    },
    photoLightBox() {
      return this.photos.map((e) => {
        return { src: require(`@/assets/${e.img}`), title: e.name };
      });
    },
  },
  methods: {
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showMultiple(a) {
      this.index = a;
      this.show();
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #1b1b1b;
}

.title {
  padding: 90px 0 50px;
  color: #262626;
}
.title-name {
  font-family: MontserratBlack;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 4rem;
  margin-bottom: 10px;
}
.main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 2px solid var(--borders-color);
  padding-top: 30px;
}
.photo {
  width: 100%;
  padding: 0 15px 30px;
  position: relative;
}
.p-img {
  width: 100%;
  height: 100%;
}
.p-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
}
.p-name {
  position: absolute;
  bottom: 30px;
  text-align: center;
  text-transform: capitalize;
  line-height: 32px;
  height: 50px;
  overflow: hidden;
  font-size: 13px;
  width: calc(100% - 30px);
  background: #0008;
  color: white;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 10px 0;
}
.p-img img:hover {
  box-shadow: 1px 3px 10px #bbb;
}

@media only screen and (max-width: 992px) {
  .container {
    padding: 0 20px;
  }
  .title {
    font-size: 23px;
    padding: 35px 0;
  }
  .title-name {
    font-size: 43px;
    line-height: 3rem;
  }
  .main {
    grid-template-columns: 1fr;
  }
  .photo {
    padding: 0;
    height: 400px;
    margin-bottom: 30px;
  }
  .p-name {
    font-size: 20px;
    bottom: 0px;
    width: 100%;
  }
}

/**       lightbox        */
.vel-img-wrapper {
  position: relative !important;
}

.prev {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(-45deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 94%;
}
.next {
  border-bottom: 10px solid #0008;
  border-right: 10px solid #0008;
  transform: rotate(135deg);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 2%;
}
@media only screen and (max-width: 992px) {
  .prev {
    left: 89%;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
  .next {
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #0008;
    border-right: 5px solid #0008;
  }
}
/**       lightbox      end  */
</style>
